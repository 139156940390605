import { useEffect } from "react"
import './Success.css'

  function Success({setNav}) {


    useEffect(() => {
      window.scrollTo(0, 0)
      setNav(true)
    }, [])

    return (
      <div className="Success">
        <h3 id="head">Thank you for Booking a session with us!</h3>
        <br/>
        <p>You'll get the payment confirmation from Razorpay if your payment was Successful.</p>
        <p>We'll reach out to you via call & e-mail once mentor confirms your session schedule.</p>
        <p></p>
        <p class="custom_button_jumbo" id="book_button_jumbo" ><span className="buttonstyleUI_jumbo"><strong><a class="nav-link active" aria-current="page" >Payment Successful</a></strong></span></p>
 
      </div>
    );
  }

export default Success;