import { db } from "../firebase.config"
import emailjs from "@emailjs/browser";
import { useState, useEffect } from "react"
import {
  collection,
  onSnapshot,
  addDoc, serverTimestamp, query, where
} from "firebase/firestore"
import './Generic.css'
import { Link } from "react-router-dom";



function Generic({setNav, bar, setBar, type}) {
  const [consultants, setConsultants] = useState([])

  // form to add request for a consulatation
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "", 
    type: "individual",
    cId: "", 
    cName: "",
    reason: "",
    isActive: true,
    isComplete: false,
    resume: "",
    date: "", 
    price: 0,
    groupPrice: 0,
    deleted: false
  })



  // when active displays request form and disables other things
  const [popupActive, setPopupActive] = useState(false)
  const [alertActive, setAlertActive] = useState(false)
  const [isVisible, setIsVisible] = useState(true);

  const consultantsCollectionRef = collection(db, "consultants")
  const formsCollectionRef = collection(db, "forms")
  const q = query(consultantsCollectionRef, where(`fields.${type}`, "==", true));


  useEffect(() => {

    emailjs.init("GzUYiLPw4nklpYLTG")
    window.scrollTo(0, 0)
    setBar(true)
    setNav(true)
    setTimeout(() => {
      setBar(false)
    }, 2000)
    // updated on changes to firestore without page reload
    onSnapshot(q, snapshot => {
      setConsultants(snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      }))
    })
  }, [])

  const sendEmail = async (n, m, mail) => {
    const serviceId = "service_cvkbd7h";
    const templateId = "template_axec606";
    try {
     
      await emailjs.send(serviceId, templateId, {
       name: n,
        mentor: m,
        email:mail
      });
    } catch (error) {
      console.log(error);
    } 
  };


  // when the user clicks submit on the form
  // checks, sends request and then empties the form for futher processing
  const handleSubmit = e => {
    e.preventDefault()

   
    if (!formClear()) return 



    addDoc(formsCollectionRef, {...form, timestamp: serverTimestamp()})
    let active = form.isActive

    if (active) {
      if ((form.type == "group" && form.groupPrice === 0) || (form.type == "individual" && form.price === 0)) {
        sendEmail(form.name, form.cName, form.email)
        window.scrollTo(0, 0)
        setAlertActive(true)
        setTimeout(() => {
          setAlertActive(false)
        }, 5000)
      } else {
        let price = (form.type == "group") ? form.groupPrice : form.price 
        window.location.href = `https://pages.razorpay.com/pl_NnKEvhBal0mOWh/view?amount=${price}`
      }
      
    } else alert("The consultant is busy we will try to schedule your appointment in the next week")
   

    setForm({
      name: "",
      email: "",
      phone: "",
      type: "individual", 
      cId: "", 
      cName: "",
      reason: "",
      isActive: true,
      isComplete: false,
      resume: "",
      date: "",
      price: 0,
      groupPrice: 0,
      deleted: false
    })
    setPopupActive(false)
  }

  const formClear = () => {
    if (
      !form.name ||
      !form.email ||
      !form.phone ||
      !form.type
    ) {
      alert("Please fill out required fields")
      return false
    }
    return true

  }

  // when user clicks close button on the form
  const handleClose = () => {
    setForm({
      name: "",
      email: "",
      phone: "", 
      type: "individual",
      cId: "", 
      cName: "", 
      reason: "",
      isActive: true,
      isComplete: false,
      resume: "",
      date: "", 
      price: 0,
      groupPrice: 0,
      deleted: false
    })
    setPopupActive(false)
  }

  

  // displays the form and pre fills some information
  const handleView = (id, name, active, date = "", price = 0, group = true, groupPrice = 25) => {
    setPopupActive(true)
    setIsVisible(group)
    setForm({...form, cId: id, cName: name, isActive: active, date: date, price: price, groupPrice: groupPrice})
  }

  return (
    <div className="Placement">

    {alertActive && <div class="alert alert-success align" role="alert">
        Appointment Booked! We will schedule your meet within a week
      </div>}

    {type === "trading" && <div class="alert alert-danger align" role="alert">
      Disclaimer: Mentor might or might not be SEBI registered RA/IA. They won't be giving any buy sell advice. This is only for learning purpose please do your own due diligence/consult your financial advisor before acting on their advice.
    </div>}

    {type === "law" && <div class="alert alert-danger align" role="alert">
      Disclaimer: You’ll be getting guidance in a pre-lawyer stage, we are not intended to market any services of lawyers listed here. 
    </div>}

    {type != "trading" && <div class="alert alert-warning d-inline-block align" role="alert">
      <strong>Please</strong> choose one counsel at a time as they are working professionals
    </div>}

    <div className="consultants" id="counsels">
      { consultants.map((consultant, i) => (
        <div className="consultant" key={consultant.id} id="consultant">
          <Link to={`/profile/${consultant.id}`} style={{ textDecoration: 'none' }}>
          
          <img className="dp"  src={consultant.dp}/>
          <p className="field subtitle">{ consultant.name } <br/> <span className="current">{ consultant.current }</span></p>
          </Link>
          
          <p className="field"><center><a href={consultant.links[0]} className="UIforcardlinks" target="_blank"><div className="buttonstyleUI">Resume</div></a> <a href={consultant.links[1]} className="UIforcardlinks" target="_blank"><div className="buttonstyleUI">Website</div></a></center></p>
          
          <Link to={`/profile/${consultant.id}`} style={{ textDecoration: 'none' }}>
          <p className="field_expertise">{ consultant.expertise.join(", ") }</p>
          </Link>

          

          <div className="buttons">
            <button class="custom-button" id="book_button" onClick={() => handleView(consultant.id, consultant.name, consultant.isActive, "", consultant.price, consultant.group, consultant.groupPrice)}>Book Session</button>
          </div>
          
        </div>
      ))}
    </div>

    { popupActive && <div className="popup">
      <div class="card">
        <div className="popup-inner">
          <div class="container">
            <div class="card-heading">
              <p class="para-text">Book a Session <br/>with<br/> {form.cName}</p>
            </div>
            <img class="image1" src= 'https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/form-image.png?alt=media&token=0b8cb75f-27f7-4ccd-8d9e-48167d856fe1' alt="Your Image" />
            
          </div>
        
       

        

        {form.type === "group" && <div class="alert alert-success align" role="alert">
          Group sessions will be 30 minutes long and will be scheduled based on mentor availability and when at least three participants are available for the time slot <Link style={{ textDecoration: 'none', color: "#b94d3d" }} to="/product"> More Information</Link>
        </div>}


        {form.type === "individual" && <div class="alert alert-success align" role="alert">
          Individual sessions are paid one on one exclusive sessions<Link style={{ textDecoration: 'none', color: "#b94d3d" }} to="/product"> More Information</Link>
        </div>}

        <form onSubmit={handleSubmit}>

          <div className="form-container" class="card-form">
            <div class = "input">
              <input
                  type="text" 
                  class = "input-field"
                  value={form.name} 
                  onChange={e => setForm({...form, name: e.target.value})} />
              <label class="input-label">Name*</label>
            </div>
          </div>

          <div className="form-container" class="card-form">
            
            <div class = "input">
              <input
                  type="text" 
                  class = "input-field"
                  value={form.email} 
                  onChange={e => setForm({...form, email: e.target.value})} />
              <label class="input-label">Email*</label>
            </div>
          </div>


          <div className="form-container" class="card-form">
            
            <div class = "input">
            <input
                type="text" 
                class = "input-field"
                value={form.phone}
                onChange={e => setForm({...form, phone: e.target.value})} />
            <label class="input-label">Phone Number*</label>
            </div>
          </div>

          {isVisible && <div className="form-container card-form" id="type-selector">
            <div className="input">
              <label className="input-label-type">Type of session*</label>
            </div>
            <div className="button-group">
              <button
                type="button"
                className={`option-button ${form.type === 'group' ? 'active' : ''}`}
                onClick={() => setForm({ ...form, type: 'group' })}
              >
                Group ({(form.groupPrice === 0) ? "Free" : "Low Cost"})
              </button>
              <button
                type="button"
                className={`option-button ${form.type === 'individual' ? 'active' : ''}`}
                onClick={() => setForm({ ...form, type: 'individual' })}
              >
                Individual (Paid)
              </button>
            </div>
          </div>
          }

          <div className="form-container" class="card-form">
            <div class = "input">
            <input
                type="text" 
                class = "input-field"
                value={form.reason}  
                onChange={e => setForm({...form, reason: e.target.value})} />
            <label class="input-label">Reason for Session*</label>
            </div>
          </div>

          {form.type === "group" && 
          <div className="form-container" class="card-form">
            <div class="description">
              <div class="topics">Topics for Group Session</div>
              <ul class="centered-list">
                <li>&nbsp;General queries like roadmap, dos and don'ts</li>
                <li>&nbsp;Avoid specific questions that take a lot of time</li>
                <li>&nbsp;For a more customized experience (example - Resume Review) try the individual sessions</li>
                </ul>
            </div>
          </div>}

          {form.type === "individual" && <div className="form-container" class="card-form">
            <div class = "input">
            <input
                type="text" 
                class = "input-field"
                value={form.resume}  
                onChange={e => setForm({...form, resume: e.target.value})} />
            <label class="input-label">Resume Link</label>
            </div>
          </div>}

          {form.type === "group" && (
            <div className="form-container card-form cost-box">
              <div className="session-details">
                <p><strong>Session Type:</strong> <span className="desc">30 Minute Google Meet</span></p>
                <p><strong>Session Cost:</strong> <span className="desc">₹{form.groupPrice}</span></p>
              </div>
            </div>
          )}

          {form.type === "individual" && (
            <div className="costmargins">
            <div className="form-container card-form cost-box">
              <div className="session-details">
              <p><strong>Session Type:</strong> <span className="desc">30 Minute Google Meet</span></p>
              <p><strong>Session Cost:</strong> <span className="desc">₹{form.price}</span></p>
              </div>
            </div>
            </div>
          )}
          
          
          <div className="buttons" class="bottombuttons">   
       
            <button type="submit" class="submit">
              Submit
            </button>
         
          <button type="button" class="remove" onClick={handleClose}>Close</button>
        
          </div>

        </form>
      </div>
    </div>
    </div>}


     
    </div>

  );
}

export default Generic;