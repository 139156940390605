import './Resources.css';
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Resources({setNav}) {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
    setNav(true);
  }, []);

  // Testimonial data
  const testimonials = [
    {
      name: 'John Doe',
      feedback: 'The service was excellent and met all my expectations!',
      role: 'Software Engineer',
    },
    {
      name: 'Jane Smith',
      feedback: 'Great advice and very prompt support!',
      role: 'Product Manager',
    },
    // Additional testimonials can be added here
  ];

  // Sample statistics data
  const statistics = [
    { label: 'Total Session Booked', value: '300+' },
    { label: 'Average Overall Rating', value: '4.5+' },
    { label: 'Successful Sessions', value: '250+' },
    { label: 'Average Counsel Rating', value: '4.8+' },
  ];

  return (
    <div className="Resources">

      <section className="statistics">
        <span className="headings"><h2>Stats for Nerds</h2></span>
        <div className="stats-list">
          {statistics.map((stat, index) => (
            <div className="stat-item" key={index}>
              <p className="label">{stat.label}</p>
              <p className="value">{stat.value}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Resources;
